.buttonCustom {
  font-weight: 400;
  height: auto;
  font-size: 14px;
  box-shadow: none;
  outline: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    border-color: transparent;
    background-color: #D9DBE4 !important;
  }
  :global {
    .ant-btn-loading-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
