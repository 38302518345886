.custom-modal-confirm-button .ant-modal-confirm-btns{
    display: flex;
    justify-content: center;
}

.custom-select .ant-select-selector .ant-select-selection-overflow{
    max-height: 80px;
    overflow-y: scroll;
}
.ant-select-selection-search {
    margin-inline-start: 0 !important;
}
ul.ant-pagination.ant-table-pagination.ant-table-pagination-right{
    display: none;
}
.custom-table{
    border-radius: 8px;
}
.avt-hover {
    cursor: pointer;
}
.ant-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 38px;
    border-radius: 50%;
    position: relative;
    border: 1px solid transparent;
    font-weight: 500;
}
.ant-avatar-string {
    position: absolute;
    transform-origin: 0 center;
}

li.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active{
    border: none;
}
.custom-progress .ant-progress-bg{
    height: 4px !important;
}

/*color of tabs*/
.ant-tabs-ink-bar{
    background-color: #ee3446 !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ee3446;
}
.ant-tabs .ant-tabs-tab:hover{
    color: #ee3446;
}
.custom-tabs-nav .ant-tabs-nav .ant-tabs-nav-wrap{
    flex-direction: row-reverse;
}
.ant-notification {
    z-index: 9999 !important;
}
.ant-select-multiple .ant-select-selector {
    padding: 2px 11px;
}

.text-ellipsis-custom{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.CustomInput .ant-form-item-required::before {
    content: '' !important;
    margin-inline-end: 0 !important;
}
.CustomInput .ant-form-item-required::after {
    display: inline-block !important;
    margin-inline-start: 4px !important;
    color: #ff4d4f !important;
    font-size: 14px !important;
    line-height: 1 !important;
    content: "*" !important;
    padding-top: 4px !important;
}
.CustomInput .ant-form-item-label label {
    display: flex;
}
.cardMedium .ant-card-head {
    padding: 16px !important;
    min-height: 0 !important;
}

.cardMedium .ant-card-body {
    padding: 16px !important;
}
.ant-form-item .ant-form-item-label {
    padding: 0 0 4px;
}
.ant-form-item .ant-form-item-label  > label {
    font-size: 13px;
}
.ant-select-single .ant-select-selector{
    font-size: 13px;
}
.ant-picker .ant-picker-input >input {
    font-size: 13px;
}
.ant-popover {
    position: absolute;
}
.borderless-input-custom {
    border-bottom: 1px solid #0000000F!important;
    border-radius: 0!important;
}
.ant-picker-dropdown .ant-picker-time-panel-column {
    overflow-y: auto!important;

    @media (min-width: 1024px) {
        overflow: hidden!important;
    }
}

.ant-picker-dropdown .ant-picker-time-panel-column:hover {
    overflow-y: auto!important;
}

.ant-table-body {
    scrollbar-width: auto;
    scrollbar-color: auto;
}

.ant-table-body::-webkit-scrollbar {
    height: 4px;
    width: 6px;
}

.ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
}

.ant-table-body::-webkit-scrollbar-thumb:hover {
    background: #d5d5d5 ;
}

.ant-input-outlined:focus {
    border-color: #818181!important;
    box-shadow: none!important;
}

.ant-input-outlined:focus-within {
    border-color: #818181!important;
    box-shadow: none!important;
}

.ant-input-outlined:hover {
    border-color: #818181!important;
}

.ant-select-outlined .ant-select-selector:hover {
    border-color: #818181!important;
}

.ant-select-outlined .ant-select-selector:focus {
    border-color: #818181!important;
    box-shadow: none!important;
}

.ant-select-outlined .ant-select-selector:focus-within {
    border-color: #818181!important;
    box-shadow: none!important;
}

.ant-picker-outlined:hover {
    border-color: #818181!important;
}

.ant-picker-outlined:focus {
    border-color: #818181!important;
    box-shadow: none!important;
}

.ant-picker-outlined:focus-within {
    border-color: #818181!important;
    box-shadow: none!important;
}

.ant-select-item-option-state {
    display: none !important;
}

.ant-select-arrow.ant-select-arrow-loading {
    color: #ff4d4f !important
}