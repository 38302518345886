@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
   /* Body/regular 400 */
  .body-regular-400 {
    @apply !font-normal !text-[13px] !leading-[20px]
  }
  /* Body/medium 500 */
  .body-medium-500 {
    @apply !font-medium !text-[13px] !leading-[20px]
  }
  /* Body/semibold 600 */
  .body-semibold-600 {
    @apply !font-semibold !text-[13px] !leading-[20px]
  }
  /* Body/regular-underline 400 */
  .body-regular-underline-400 {
    @apply !font-normal !text-[13px] !leading-[20px] !underline
  }
  /* Body/regular-strikethrough 400 */
  .body-regular-strikethrough-400 {
    @apply !font-normal !text-[13px] !leading-[20px] !line-through
  }
  /* Footnote/description 400 */
  .footnote-description-400 {
    @apply !font-normal !text-xs !leading-[20px]
  }
  /* Footnote/description 500 */
  .footnote-description-500 {
    @apply !font-medium !text-xs !leading-[20px]
  }
   /* Footnote/description 600 */
   .footnote-description-600 {
    @apply !font-semibold !text-xs !leading-[20px]
  }
  /* Footnote/system-monospace 400 */
  .footnote-system-monospace-400 {
    @apply !font-normal !text-xs !leading-[20px]
  }
  /* Body/code */
  .body-code {
    @apply !font-normal !text-[13px] !leading-[22px]
  }
  /* H5/regular 400 */
  .h5-regular-400 {
    @apply !font-normal !text-[15px] !leading-[22px]
  }
  /* H5/medium 500 */
  .h5-medium-500 {
    @apply !font-medium !text-[15px] !leading-[22px]
  }
  /* H5/medium 500 */
  .h5-medium-500 {
    @apply !font-medium !text-[15px] !leading-[22px]
  }
  /* H5/semibold 600 */
  .h5-semibold-600 {
    @apply !font-semibold !text-[15px] !leading-[22px]
  }
  /* H4/Regular 400 */
  .h4-regular-400 {
    @apply !font-normal !text-lg !leading-[25px]
  }
  /* H4/medium 500 */
  .h4-medium-500 {
    @apply !font-medium !text-lg !leading-[25px]
  }
  /* H4/Semibold 600 */
  .h4-semibold-600 {
    @apply !font-semibold !text-lg !leading-[25px]
  }
  /* H3/regular 400 */
  .h3-regular-400 {
    @apply !font-normal !text-[22px] !leading-[30px]
  }
  /* H3/medium 500 */
  .h3-medium-500 {
    @apply !font-medium !text-[22px] !leading-[30px]
  }
  /* H3/Semibold 600 */
  .h3-semibold-600 {
    @apply !font-semibold !text-[22px] !leading-[30px]
  }
  /* .H2/medium 500 */
  .h2-medium-500 {
    @apply !font-medium !text-[28px] !leading-[36px]
  }
  /* H2/Semibold 600 */
  .h2-semibold-600 {
    @apply !font-semibold !text-[28px] !leading-[36px]
  }
  /* H1/medium 500 */
  .h1-medium-500 {
    @apply !font-medium !text-[36px] !leading-[46px]
  }
  /* H1/Semibold 600 */
  .h1-semibold-600 {
    @apply !font-semibold !text-[36px] !leading-[46px]
  }
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono',
    'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@font-face {
  font-family: "Helvetica";
  src: url("/fonts/Helvetica.ttf");
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
    --border-primary: #ee3446;
    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb), 0.88);
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
  width: 100%!important;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.box-class {
  /* width: 400px;
  height: 199px; */
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.02);
}
.breadcrum-custom > ol > li {
  display: flex;
}
.recharts-responsive-container {
  margin: 0 auto;
}

/* hide footer of label in date rage */
.rdrInputRanges{
  display: none;
}

/*home-page*/
.background-gradient {
  background-color: transparent;
  background-image: radial-gradient(at top left, #421A4C 63%, #eb3444 96%);
  opacity: 0.93;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-shape .elementor-shape-fill {
  fill: #fff;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.elementor-shape[data-negative=false].elementor-shape-bottom, .elementor-shape[data-negative=true].elementor-shape-top {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);}

.hover-class:hover{
  /*border-style: solid;*/
  /*border-width: 1px 1px 1px 1px;*/
  /*border-color: #00ce1b;*/
  border-inline: 1px solid #00ce1b;
  box-shadow: 0px 0px 55px 0px rgba(0,0,0,0.19);

}
.hover-icon:hover{
  color: #00ce1b
}
.progress-bar-value{
  position: relative;
  float: right;
  font-family: 'Jost', sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  padding: 5px 5px;
  color: #fff;
  background-color: #dd1f1f;
}
.progress-bar-value::before{
  position: absolute;
  content: "";
  bottom: -6px;
  right: 0;
  width: 0;
  height: 0;
  border-right: 10px solid #f65551;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #dd1f1f;
}
.progress-bar-title{
  font-size: 15px;
  font-family: 'Jost', sans-serif;
  font-weight: 800;
  margin-bottom: 10px;
  color: #2b2b2d;
  display: inline-block;
  max-width: 285px;
}
[data-test-id='button-primary']:focus-visible{
  outline: none;
}